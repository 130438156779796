import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updateExtraCriteria } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiExtraCriteria, ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpdateSpotOnExtraCriteriaMutationResult = Omit<
  UseMutationResult<ExtraCriteria, Error, ApiExtraCriteria>,
  'mutateAsync'
> & {
  updateExtraCriteria: UseMutationResult<ExtraCriteria, Error, ApiExtraCriteria>['mutateAsync']
}

export function useUpdateSpotOnExtraCriteriaMutation(
  options?: UseMutationOptions<ExtraCriteria, ApiExtraCriteria>,
): UseUpdateSpotOnExtraCriteriaMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiExtraCriteria) => updateExtraCriteria(variables),
  })

  return { updateExtraCriteria: mutateAsync, ...mutationResult }
}
