import * as React from 'react'
import { useEffect } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnEboilerNetworkChargesForm from '@/features/resource/components/SpotOnEboilerNetworkChargesForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { useSpotOnExtraCriteriaQuery } from '@/features/resource/hooks/useSpotOnExtraCriteriaQuery'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

function SpotOnEboilerNetworkChargesPage() {
  const { resource, setActivePage } = useResourceSpotOnEboilerDetails()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()
  const { extraCriteria } = useSpotOnExtraCriteriaQuery(
    {
      scheduleProgramId: spotOnScheduleProgram?.id ?? '',
      customerId: resource?.customerId ?? '',
    },
    { enabled: Boolean(resource) },
  )

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_NETWORK_CHARGES.routePath)
  }, [])

  if (!extraCriteria) return <FullPageSpinner />

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <CustomTypography gutterBottom variant="h3">
        {resource?.resourceName}
      </CustomTypography>
      <SpotOnEboilerNetworkChargesForm
        customerExtraCriteria={extraCriteria}
        customerId={resource?.customerId ?? ''}
        scheduleProgramId={spotOnScheduleProgram?.id ?? ''}
      />
    </MainContentContainer>
  )
}

export default SpotOnEboilerNetworkChargesPage
