import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updateSchedule } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiUpdateSchedule, Schedule } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpdateSpotOnScheduleMutationResult = Omit<
  UseMutationResult<Schedule, Error, ApiUpdateSchedule>,
  'mutateAsync'
> & {
  updateSchedule: UseMutationResult<Schedule, Error, ApiUpdateSchedule>['mutateAsync']
}

export function useUpdateSpotOnScheduleMutation(
  options?: UseMutationOptions<Schedule, ApiUpdateSchedule>,
): UseUpdateSpotOnScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiUpdateSchedule) => updateSchedule(variables),
  })

  return { updateSchedule: mutateAsync, ...mutationResult }
}
